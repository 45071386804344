:root {
  --primary-color: #66b737;
  --success-color: rgb(102, 183, 55);
  --error-color: rgb(229, 8, 31);
  --primary-contrast-color: #ffffff;
  --primary-light-color: #a6d58b;
  --text-on-primary-color: #ffffff;
  --secondary-color: #59893e;
  --gray-color: #979797;
  --background-color: #eff3ec;
  --contrast-color: #0071D7;

  --card-header-background-color: #fff;
  --card-subheader-color: #212529;
  --card-header-font-weight: 400;
  --navigation-background-color: #fff;
  --menu-item-color: #080707;
  --menu-item-icon-color: #707070;
  --primary-text-color: rgb(33, 37, 41);
  --settings-header-color: #fff;
  --universe-media-color: #d39d45;
  --universe-media-selected-color: #f0dec0;
  --universe-organisation-color: #16b3e3;
  --universe-organisation-selected-color: #c0e0f0;
  --universe-media-group-color: #844712;
  --universe-media-group-selected-color: #cb850c;
  --universe-organisation-group-color: #0b26a2;
  --universe-organisation-group-selected-color: #909cd4;
  --universe-background-color: #e8e8e8;
  --universe-label-color: #212529;
  --universe-label-background-color: rgb(255, 255, 255, 0.8);

  --shadow-color-for-object-flows: rgb(229 8 31);
  --burger-menu-icon-color: white;
  --avatar-bg-color: white;
  --table-row-complementary-color: rgb(220 220 220);
  --expand-more-icon: #212529;
  --flows-nodes-color: rgb(81, 146, 202);
  --flows-transfer-color: rgba(0, 136, 254, 0.5);
  --flows-funding-color: rgba(68,208,87,0.9);
  --flows-label-color: #212529;
  --ads-setting-container-bg-color: white;
  --others-flow-color: #a4b2be;
  --ads-min-width-for-all-columns: 900;
  --max-header-width: auto;
  --max-content-width: auto;

  --mui-palette-primary-main: #66b737;
  --mui-palette-primary-mainChannel: #66b737;
}

$ads-min-width-for-all-columns: 900px;

body {
  font-family: var(--font-family);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

.MuiTypography-root {
  font-family: var(--font-family) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span.univ-label {
  color: var(--universe-label-color);
  background-color: var(--universe-label-background-color);
  padding: 0.2em;
}

#content {
  margin-top: 64px;
}

.max-header-width {
  width: 100%;
  max-width: var(--max-header-width);
  margin: auto;
  box-sizing: border-box;
}

.max-content-width {
  width: 100%;
  max-width: var(--max-content-width);
  margin: auto;
  box-sizing: border-box;
}

.accordion-header {
  color: antiquewhite;
  border: 2px solid var(--primary-color);
}

.user-info {
  margin-top: 0.5rem;
}

.MuiButton-contained {
  text-transform: none !important;
}

.MuiAppBar-root {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.rtr .MuiCard-root {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12) !important;
}


.fhj .MuiCardHeader-root {
  color: var(--card-header-color);
  background-color: var(--primary-color);
  border-bottom: 2px solid #E0E0E0;
}

.rtr .MuiCardHeader-root {
  color: var(--primary-color);
  background-color: #fff;
  border-bottom: 1px solid #E0E0E0;
}


.MuiCardHeader-subheader {
  color: var(--card-subheader-color) !important;
}

.MuiCardContent-root {
  background-color: var(--card-backgound-color);
}

.MuiCardContent-root .MuiAccordion-root {
  box-shadow: none !important;
}

.MuiButton-root {
  box-shadow: none !important;
}

.MuiCard-root .MuiCard-root {
  box-shadow: none !important;
  border: 1px solid rgba(0,0,0,.1) !important;
}

.MuiPaper-root {
  margin-bottom: 1em;
  position: relative;
}

.MuiCardActions-root {
  background-color: #fff !important;
  color: var(--text-on-primary-color) !important;
  width: auto !important;
  margin: 10px;
  position: relative;
  width: auto;
}

.MuiFormControl-root {
  margin-top: 0.8em !important;
}

.MuiAccordion-root:before {
  display: none;
}

.MuiAccordionSummary-expandIconWrapper {
  color: var(--primary-color) !important;
}

.MuiSlider-markLabel {
  font-size: 0.6rem !important;
}

.MuiChip, .MuiBadge-badge {
  background-color: var(--contrast-color) !important;
  color: #fff;
}

.MuiToolbar-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.MuiDivider-root {
  border-color: rgba(0,0,0,.1) !important;
}

.MuiDrawer-docked, .MuiDrawer-root, .MuiDrawer-paper {
  border: none !important;
  box-shadow: none !important;
}

.MuiAccordionDetails-root {
  background-color: whitesmoke;
}

.MuiBottomNavigationAction-root.Mui-selected, .MuiBottomNavigationAction-root .Mui-selected {
  color: #fff !important;
  font-size: 0.75rem !important;
}

.MuiButton-root {
  color: var(--text-on-primary-color) !important;
}

.MuiRadioGroup-root, .MuiRadio-action {
  border-radius: 4px !important;
}

.MuiButton-contained-root {
  color: var(--text-on-primary-color) !important;
}

// Horizontal scrolling for fields (bug with windows)
// .MuiPopper-root {
//   overflow-x: scroll !important;
// }

// .MuiAutocomplete-paper {
//   overflow-x: scroll !important;
//   width: fit-content !important;
// }

// .MuiAutocomplete-listbox {
//   overflow-x: scroll !important;
// }

.form-control, .form-select {
  background-color: #fff !important;
}

.form-group, .form-floating {
  margin: 15px auto;
}

.modal {
  z-index: 10001 !important;
}

.modal-backdrop.show {
  z-index: 10000 !important;
}

.modal-body {
  display: flex;
  align-items: center;
}

.modal-body span {
  margin-left: 10px;
}

.no-padding {
  padding: 0 !important;
}

.no-text-decoration {
  text-decoration: none !important;
}

.flex-row-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

.dark-font {
  color: var(--primary-text-color) !important;
}
