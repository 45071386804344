

.autocomplete-outer-container {
  padding: 1em;
}

.autocomplete-inner-container {
  padding-top: 0.3em;
}

.ads-setting-container {
  margin-bottom: 1em;
  padding: 0.75em;
  background-color: var(--ads-setting-container-bg-color);
  border-radius: 5px;
  overflow: hidden;
}

.objects-list-item-icon {
  margin-right: 0.5em;
}

.objects-list-open-icon {
  &:hover {
    cursor: pointer;
  }
}

.period-slider-container {
  padding: 0 1em;
}

.ads-table {
  margin-bottom: .5em;
}

.ads-table-header-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25em;
  height: 95px;
}

.ads-table-header-item-title {
  margin: 0.4em 0;
  font-size: 1.1em;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}

.ads-table-header-item-filter {
  margin-top: 0 !important;
  opacity: .7;
}

.dialog-ad-header {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-ad-close-icon {
  &:hover {
    cursor: pointer;
  }
}

.period-filter-container {
  display: flex;
  align-items: center;
  
  .period-filter-container-span {
    margin-right: .25em;
  }
}

.combined-column-inner-container {
  margin-bottom: 0.25em;
  white-space: pre;
}

.row-column-sep-n {
  white-space: pre;
}

@media screen and (max-width: var(--ads-min-width-for-all-columns)) {
.ads-table-header-item {
    height: 120px;
  }
}

@media screen and (max-width: 680px) {
  .ads-table-header-item {
      height: 150px;
    }
  }

