.MuiAccordion-root {
  width: 100%;
  border: 1px solid var(--secondary-color);
}

.settings .MuiAccordionSummary-root {
  background-color: transparent !important;
  border-radius: 9px;
}

.settings-card {
  border: 0 !important;
  margin-bottom: 0.5em;
}

.settings-banner-success {
  background-color: color-mix(in srgb, var(--success-color) 30%, white);
  color: color-mix(in srgb, var(--success-color) 30%, black);
}

.settings-banner-error {
  background-color: color-mix(in srgb, var(--error-color) 30%, white);
  color: color-mix(in srgb, var(--error-color) 30%, black);
}

.settings-banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px !important;
}

.settings-banner-message {
  flex-grow: 1;
}

.text-italic {
  font-style: italic;
}

.text-red {
  color: var(--error-color);
}
