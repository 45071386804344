.swiper-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25em;
}

.swiper-container i {
  margin: auto 20px;
  cursor: pointer;
}

.swiper-container i svg {
  height: 50px;
}

.swiper-custom-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

.news-row {
  margin-bottom: 0.5em;
}

.swiper-slide {
  height: auto !important;
}

.swiper-content {
  height: 100% !important;
}

.swiper {
  z-index: unset !important;
}

.swiper-wrapper {
  z-index: unset !important;
}

.start-site-info-card {
  height: 100%;
}

.start-site-info-card-row > * {
  margin-bottom: 0.5em;
}

@media screen and (max-width: 600px) {
  .home-accordion {
    display: block !important;
  }

  .home-news-card {
    display: none;
  }

  .review-swiper-button-next {
    display: none;
  }

  .review-swiper-button-prev {
    display: none;
  }
}

.home-accordion {
  display: none;
  .accordion-item {
    border: 1px solid gray;
    border-radius: 0.25rem;
  }

  .accordion-header {
    border: none;
  }
}
