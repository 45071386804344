.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-brand-wrapper * {
  height: 40px;
}

$footer-height: 30px;
$mobile-footer-height: 56px;

#content {
  padding: 1em;
  padding-bottom: $footer-height;
  flex: 1;
  min-height: calc(100% - 64px);
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

.root-box {
  display: flex;
  min-width: 100%;
  min-height: 100%;
  flex: 1;
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

footer {
  position: fixed;
  font-size: smaller;
  padding: 0;
  left: 0;
  bottom: 0;
  height: $footer-height;
  width: 100%;
  background: #ddd;
  -webkit-box-shadow: 0 8px 6px 6px black;
  -moz-box-shadow: 0 8px 6px 6px black;
  box-shadow: 0 8px 6px 6px black;
}

.footer-page {
  display: flex;
  flex-direction: row;
  font-size: 12px !important;
  padding: 40px 10px;
}

.footer-page .MuiIconButton-root {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--primary-text-color);
}

.footer-page .MuiIconButton-root:hover {
  border-color: var(--primary-color);
}

.footer-page a {
  text-decoration: none;
  color: var(--primary-text-color) !important;
  font-weight: 700;
}

.footer-page a:hover {
  color: var(--primary-color) !important;
}

.footer-page .MuiList-root {
  width: 250px;
}

.topbar-toogle-button {
  color: black;
}

.topbar-content {
  display: flex;
  justify-content: space-between;
  padding-right: 4px;
  padding-left: 4px;
}

.topbar-content .MuiButton-root {
  margin: 0 6px;
  font-weight: 700 !important;
}

.topbar-thin .MuiIconButton-root, .topbar-thin .MuiIconButton-root:hover  {
  color: var(--primary-contrast-color) !important;
  background-color: var(--primary-color) !important;
  transition: none !important;
}

.topbar-thin .MuiToolbar-root {
    height: 38px !important;
    min-height: 38px !important;
  }

.horizontal-menu {
  width: 100%;
  display: flex;
  height: 70px;
  background-color: #fff;
}

.horizontal-menu .MuiListItem-root {
  width: auto !important;
}

.horizontal-menu .MuiButton-outlined   {
  color: var(--primary-color) !important;
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: 700;
  height: 42px;
  transform: none !important;
  margin-left: 10px;
  border: 2px solid var(--primary-color) !important;
  padding: 10px 20px!important;
  border-radius: 9px;
}

.horizontal-menu .MuiTypography-root {
  font-size: inherit !important;
  font-weight: inherit !important;
}

.horizontal-menu-logo > * {
  color: var(--primary-color) !important;
  height: 40px;
  margin-left: 20px;
  max-width: calc(100% - 20px) !important;
}

.horizontal-menu-list {
  display: flex !important;
}

.horizontal-menu-sub .MuiListItemButton-root {
  height: auto;
  font-size: 14px;
}

.horizontal-menu-sub .MuiTypography-root {
  font-size: 14px;
}

.drawer-outer-container {
  background-color: var(--navigation-background-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid rgba(0,0,0,.08);
}

.drawer-inner-container-selected {
  color: var(--secondary-color);
}

.user-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.toogle-outer-container {
  display: flex;
}

.page-title-container {
  color: var(--text-on-primary-color);
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: 700;
  padding-left: 0.5em;
}

.drawer-inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.7em;

  a {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  ul {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 600px) {
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5em;
  }
}

@media screen and (min-width: 1080px) {
  .horizontal-menu-toogle {
    display: none !important;
  }
}

@media screen and (max-width: 1080px) {
  .horizontal-menu-list {
    display: none !important;
  }

  .horizontal-menu .MuiButton-outlined   {
    font-size: 24px !important;
    font-weight: normal !important;
    color: #fff !important;
    border: none !important;
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  #content {
    padding-bottom: $mobile-footer-height;
  }

  footer {
    height: $mobile-footer-height;
  }

  .root-box {
    display: block;
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .drawer-outer-container {
    justify-content: flex-start;
  }

  .drawer-inner-container {
    height: 35px;
    font-size: 1em;
  }
}

@media screen and (max-width: 800px) {
  .footer-page {
    flex-direction: column-reverse;
  }
}


@import "custom";
