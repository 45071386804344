.nav-tabs .nav-link.active {
  border-color: gray gray #e8e8e8;
  border-bottom: 1.5px solid #e8e8e8;
}

.nav-tabs {
  border-bottom: 1px solid gray;

  &.nav-link {
    color: #0c4c0c;
  }
}

.flows {
  border-top: none;
  margin-bottom: 0.5em;
}

.flows-card-title {
  margin-bottom: 0 !important;
  font-size: 1em;
}

.datatable-outer-container {
  width: 100px;
}

@media screen and (max-width: 480px) {
  .second-tab-heading {
    font-size: 14px;
  }
}

@media screen and (max-width: 430px) {
  .flows-nav {
    font-size: 14px;
  }

  .second-tab-heading {
    & li {
      width: 100%;

      & button {
        width: 100%;
      }
    }

    &.nav-tabs .nav-link {
      margin-bottom: 0;
    }

    &.nav-tabs .nav-link.active {
      border: 1px solid gray;
      border-radius: 0.25em;
    }

    &.flows-nav {
      border: 0;
    }
  }
}

@media screen and (max-width: 400px) {
  .flows-nav {
    font-size: 13px;
  }
}

@media screen and (max-width: 360px) {
  .flows-nav {
    font-size: 10px;
  }
}

.col-center-element {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
  flex-direction: column;
}

.landscape-toogle-switch {
  padding-top: 0.5em;
}
