$body-bg: #e8e8e8;
//$body-color: #111;
$light: #66b737;

$navbar-light-color: antiquewhite;
$navbar-light-active-color: antiquewhite;
$nav-tabs-border-color: #66b737;

$component-active-bg: #66b737;
$accordion-button-bg: #66b737;
$accordion-button-color: antiquewhite;

$card-bg: #e8e8e8;
$card-border-color: gray;
$card-title-color: antiquewhite;

$nav-tabs-link-active-color: #495057;

.card {
  margin-bottom: 0.5em;
}

.card-title {
  background-color: $light;
  background-color: rgba(102, 183, 55, 1);
  color: antiquewhite;
  padding: 1em;
}

.welcome_title.card-title {
  background-color: rgb(235, 187, 84);
  color: antiquewhite;
  padding: 1em;
}



.overview-accordion-text-container {
  margin-bottom: 0;

  .accordion-header {
    color: black;
    border: 1px solid darkgrey;
    border-radius: 0.375rem;
  }

  .accordion-button {
    color: black;
    padding: 0.5em;
    background-color: transparent;
  }

  .accordion-button:not(.collapsed) {
    color: black;
    background-color: #cfcfcf;
  }

  .accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgb(195 195 195 / 25%);
  }

  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
}
.values-body-segment {
  margin-top: 0.5em;
}

/* import bootstrap to set changes */


.rdt_TableBody {
  overflow: visible;

  input[type="checkbox"] {
    display: none;
  }

  button[disabled] {
    display: none;
  }
}

.multi-select {
  width: 100%;
}

.dropdown-content {
  input[type="checkbox"] {
    display: none;
  }
}

.XlRpR {
  display: block !important;
}

.rdt_TableRow {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.tableText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

:root {
  .value-segment {
    margin-bottom: 0.5em;
  }

  .values-body-segment {
    .card-body {
      padding: 0.75em;
    }
  }

  .active-bottom-tab-item {
    color: #0c4c0c;
  }

  @media screen and (max-width: 600px) {
    .values-body-segment {
      .card-body {
        padding: 0.25em;
      }
    }
  }
}

.col-center-element-with-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.col-center-element-without-heading {
  display: flex;
  align-items: center;
  align-items: flex-end;
  padding-bottom: 0.6em;
  justify-content: center;
}
