

.flows .rc-slider-mark-text {
    transform: translateX(-50%) rotate(-90deg) translateX(-20%) !important;
}

div.label {
    margin-top: 0.3em;
    font-size: small;
}

div.settings {
  /* border: 1px solid silver; */
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 3.5em;
  /* margin-bottom: 0.5em; */
}

.custom-tooltip {
    box-shadow: rgba(149, 157, 165, 0.8) 0px 8px 24px;
    border-radius: 5px;
    background-color: white;
    padding: 0.2em 0.5em 0.2em 0.5em;
}

.flows .node_label {
    font-weight: lighter;
}

.rc-slider-handle-2,
.rc-slider-handle-1 {
    transform: translate(-50%,-10%) !important;
}

.ads-transfer-container {
    padding: 1em;
}

@media screen and (max-width: 600px) {
    .ads-transfer-container {
        padding: .25em;
    }
}
