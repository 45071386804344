.recharts-tooltip-wrapper .custom-tooltip {
  width: 280px;
  margin: 0;
  line-height: 24px;
  border: 1px solid #f5f5f5;
  background-color: hsla(0, 0%, 100%, 0.8);
  padding: 10px;
}

.top .rc-slider-handle {
  width: "18" !important;
  height: "18";
}

.top .rc-slider-mark-text {
  transform: translateX(-50%) rotate(-90deg) translateX(-20%) !important;
}

.recharts-tooltip-wrapper .custom-tooltip .label {
  margin: 0;
  color: #666;
  font-weight: 700;
}

.recharts-tooltip-wrapper .custom-tooltip .desc {
  margin: 0;
  color: #999;
}

.recharts-tooltip-wrapper .custom-tooltip .intro {
  border-top: 1px solid #f5f5f5;
  margin: 0;
}

.info {
  font-size: small;
  margin-top: 1em;
  margin-bottom: 2em;
  color: #666;
}

.control-form {
  margin-left: 1.5em;
  flex-shrink: unset;
}
