.result-line {
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 1px solid rgb(143 135 135 / 50%);
}

.result-line:hover {
  background-color: var(--primary-light-color);
  cursor: pointer;
}
