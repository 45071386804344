/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../public/fonts/roboto-v30-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

:root {
  --font-family: Roboto, sans-serif;
  --primary-color: #66b737;
  --primary-contrast-color: #ffffff;
  --success-color: rgb(102, 183, 55);
  --charts-title-color:rgb(102, 183, 55);
  --charts-subtitle-color: #000000;
  --card-backgound-color: #f9faf5;
  --card-header-color: #f8fbeb;
  --card-header-background-color: #66b737;;
  --card-subheader-color: #f8fbeb;
  --navigation-background-color: #f9faf5;
  --secondary-color: #cfebbd;
  --text-on-primary-color: #f8fbeb;
}

.MuiAccordionSummary-root {
  color: var(--card-header-color) !important;
  background-color: var(--card-header-background-color) !important;
}

.MuiCardContent-root .MuiAccordion-root, .MuiAccordion-root:last-of-type, .MuiAccordionDetails-root {
  border-radius: 4px;
}

.drawer-outer-container {
  border-right: 2px solid rgba(0, 0, 0, 0.08);
}

.MuiRadio-root .MuiRadio-label {
  color: var(--card-header-color);
}

.Mui-checked label.MuiRadio-label {
  color: #333 !important;
}

.MuiAccordionSummary-expandIconWrapper {
  color: var(--card-header-color) !important;
}

.settings .MuiAccordionSummary-expandIconWrapper {
  color: var(--primary-color) !important;
}

.languageSettings .MuiButtonBase-root {
  color: var(--card-header-color) 
}

